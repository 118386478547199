import * as Sentry from "@sentry/browser";
window.Sentry = Sentry;
window.Sentry.init({
  integrations: [],
});

require("./bootstrap");

const covercard = document.getElementById("covercard");
if (covercard) {
  covercard.addEventListener("keydown", function (event) {
    if (["Delete", "Backspace", "Enter", "Tab", "Dead"].includes(event.key)) {
      return;
    }
    if (
      !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(event.key)
    ) {
      event.preventDefault();
      return;
    }
    if (covercard.value.length >= 20) {
      event.preventDefault();
      return;
    }
  });

  covercard.addEventListener("input", function () {
    if (covercard.value.includes("^")) {
      covercard.value = covercard.value.replace(/\^/m, "");
    }
  });
}
