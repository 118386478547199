window._ = require("lodash");

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

import * as bootstrap from "bootstrap";
import Alpine from "alpinejs";

window.Alpine = Alpine;
window.bootstrap = bootstrap;

window.$ = window.jQuery = require("jquery");

require("datatables.net");
require("datatables.net-bs5");

window.$(function () {
  window.$(".table-dt").DataTable({
    language: require("datatables.net-plugins/i18n/de-DE.json"),
    createdRow: function (row) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (
        row.dataset.uuid &&
        urlParams.has("uuid") &&
        row.dataset.uuid === urlParams.get("uuid")
      ) {
        row.classList.add("table-info");
        setTimeout(() => {
          row.classList.remove("table-info");
        }, 1500);
      }
    },
  });
});

Alpine.start();

require("@fortawesome/fontawesome-pro/js/solid");
require("@fortawesome/fontawesome-pro/js/fontawesome");
